

























































  import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
  export default class DataQualityIndicator extends Vue {
  @Prop({ type: Number, default: 0 }) showOverallStatus!: Number;
  @Prop({ type: String, default: "" }) link?: string;

  navigateToLink(): void {
    if (this.link === undefined) {
      throw new Error("Link is undefined");
    }
    this.$router.push(this.link);
  }
  }
