var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.rows,"headers":_vm.headers,"hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',_vm._l((_vm.headers),function(h,index){return _c('th',{key:h.text,class:{
          'first-column': index === 0,
          'second-column': h.text == 'Overall Status'
        }},[_vm._v(" "+_vm._s(h.text)+" "),(h.tooltip !== undefined)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey","size":"medium"}},on),[_vm._v("mdi-help-circle")])]}}],null,true)},[_c('span',{staticStyle:{"max-width":"300px"}},[_vm._v(_vm._s(h.tooltip))])]):_vm._e(),(h.children)?_c('div',{staticStyle:{"padding-top":"10px"}},_vm._l((h.children),function(child,index){return _c('th',{key:index,staticClass:"children"},[_vm._v(" "+_vm._s(child.text)+" "),(child.tooltip !== undefined)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey","size":"medium"}},on),[_vm._v("mdi-help-circle")])]}}],null,true)},[_c('span',{staticStyle:{"max-width":"300px"}},[_vm._v(_vm._s(child.tooltip))])]):_vm._e()],1)}),0):_vm._e()],1)}),0)]},proxy:true},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',_vm._l((_vm.headers),function(header,index){return _c('td',{key:index,class:{
          'first-column': index === 0,
          'second-column': header.text == 'Overall Status'
        }},[(item[header.value])?_c('DataQualityTableRow',{attrs:{"dataQualityRow":item[header.value],"type":header.type,"link":_vm.links ? _vm.links[index] : null}}):_vm._e(),_c('div',[(header.children)?_c('v-row',_vm._l((header.children),function(child,index){return _c('v-col',{key:index,staticClass:"pa-0"},[(item[child.value])?_c('DataQualityTableRow',{attrs:{"dataQualityRow":item[child.value],"type":child.type,"link":_vm.links ? _vm.links[index] : null}}):_vm._e()],1)}),1):_vm._e()],1)],1)}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }