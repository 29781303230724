












  import { Vue, Component } from "vue-property-decorator";
  import DataAvailabilityModal from "@/components/dataQuality/dataAvailabilityModal/DataAvailabilityModal.vue";
  import DataQualityTable from "@/components/dataQuality/dataQualityTable/DataQualityTable.vue";
  import DataQualityIndicatorLegend from "@/components/dataQuality/DataQualityIndicatorLegend.vue";
  import { getModule } from "vuex-module-decorators";
  import VesselsModule from "@/store/clients/Vessels.module";
  import DataQualityModule from "@/store/clients/DataQuality.module";
  import store from "@/store";
  import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";

  const Vessels = getModule(VesselsModule, store);
  const DataQuality = getModule(DataQualityModule, store);

@Component({
  components: {
    DataQualityTable,
    DataAvailabilityModal,
    DataQualityIndicatorLegend,
  },
})
  export default class DataAvailability extends Vue {
  dialog = false;

  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels(), DataQuality.refreshDataAvailability()]);
  }

  get dataAvailability(): any {
    var dataAvailability = DataQuality.dataAvailability.map(item => {
      const vessel = Vessels.extendedVessels.find(vessel => vessel.id === item.vesselId);
      return {
      vesselName: {
        value: vessel ? vessel.name : "Unknown Vessel",
      },
      overallStatus: {
        value: item.data.find(row => row.featureName === "Overall Status")?.dataQualityIndicator,
      },
      stw: {
        value: item.data.find(row => row.featureName === "Speed Through Water")?.dataQualityIndicator,
      },
      draft: {
        value: item.data.find(row => row.featureName === "Draft (mean)")?.dataQualityIndicator,
      },
      speedLog: {
        value: item.data.find(row => row.featureName === "speedLog")?.dataQualityIndicator,
      },
      gefoc1: {
        value: item.data.find(row => row.featureName === "gefoc1")?.dataQualityIndicator,
      },
      gefoc2: {
        value: item.data.find(row => row.featureName === "gefoc2")?.dataQualityIndicator,
      },
    };
  });
    return dataAvailability;
  }

  get tableLoading(): boolean {
    return DataQuality.loadingState;
  }

  headers: DataQualityHeader[] = [
    {
      text: "Vessel",
      type: "string",
      value: "vesselName",
    },
    {
      text: "Overall Status",
      type: "dataIndicator",
      value: "overallStatus",
      tooltip: "Indicates the overall data availability status of the vessel.",
    },
    {
      text: "STW",
      type: "dataIndicator",
      value: "stw",
    },
    {
      text: "Draft",
      type: "dataIndicator",
      value: "draft",
    },
    {
      text: "SP#1",
      type: "dataIndicator",
      value: "speedLog",
    },
    {
      text: "SP#2",
      type: "dataIndicator",
      value: "gefoc1",
    },
    {
      text: "ME #1FOC",
      type: "dataIndicator",
      value: "speedLog",
    },
    {
      text: "ME #2FOC",
      type: "dataIndicator",
      value: "speedLog",
    },
    {
      text: "G/E #1FOC",
      type: "dataIndicator",
      value: "gefoc1",
    },
    {
      text: "G/E #2FOC",
      type: "dataIndicator",
      value: "gefoc2",
    },
    {
      text: "G/E #3FOC",
      type: "dataIndicator",
      value: "gefoc1",
    },
    {
      text: "G/E #4FOC",
      type: "dataIndicator",
      value: "speedLog",
    },
  ];

  closeModal(): void {
    this.dialog = false;
  }
  }
