



















  import { Component, PropSync, Vue } from "vue-property-decorator";
  import DataAvailabilityModalFooter from "./DataAvailabilityModalFooter.vue";
  import DataAvailabilityModalConfigureTags from "./DataAvailabilityModalConfigureTags.vue";
  import { DataQualityTagConfiguration } from "@/types/dataQuality/dataQualityTagConfiguration";
  import { getModule } from "vuex-module-decorators";
  import DataQualityModule from "@/store/clients/DataQuality.module";
  import store from "@/store";
  import { DataAvailabilityConfigType } from "@/types/dataQuality/dataAvailability";

  const DataQuality = getModule(DataQualityModule, store);

@Component({
  components: {
    DataAvailabilityModalFooter,
    DataAvailabilityModalConfigureTags,
  },
})
  export default class DataAvailabilityModal extends Vue {
  @PropSync("isOpen", { type: Boolean, default: false }) dialog!: boolean;

  applySameConfig = false;
  dataAvailabilityConfigModel: DataQualityTagConfiguration[] = [];

  get dataAvailabilityConfig(): DataQualityTagConfiguration[] {
    var config: DataQualityTagConfiguration[] = DataQuality.dataAvailabilityConfig.map(item => ({
      tagName: item.featureName,
      observeTimeSpan: this.convertToCorrectTimeUnit(item.warningTimeSeconds)[0],
      observeTimeSpanUnit: this.convertToCorrectTimeUnit(item.warningTimeSeconds)[1],
      criticalTimeSpan: this.convertToCorrectTimeUnit(item.criticalTimeSeconds)[0],
      criticalTimeSpanUnit: this.convertToCorrectTimeUnit(item.criticalTimeSeconds)[1],
    }));
    return config;
  }

  async created(): Promise<void> {
    await Promise.all([DataQuality.refreshDataAvailabilityConfig()]);
    this.dataAvailabilityConfigModel = this.dataAvailabilityConfig;
  }

  convertToCorrectTimeUnit(numberInSeconds: number): [string, string] {
    if (numberInSeconds >= 31536000) {
      return [Math.round(numberInSeconds / 31536000).toString(), "year(s)"];
    }
    if (numberInSeconds >= 2592000) {
      return [Math.round(numberInSeconds / 2592000).toString(), "month(s)"];
    }
    if (numberInSeconds >= 86400) {
      return [Math.round(numberInSeconds / 86400).toString(), "day(s)"];
    }
    if (numberInSeconds >= 3600) {
      return [Math.round(numberInSeconds / 3600).toString(), "hour(s)"];
    }
    if (numberInSeconds >= 60) {
      return [Math.round(numberInSeconds / 60).toString(), "minute(s)"];
    }

    return [numberInSeconds.toString(), "seconds"];
  }

  convertToSeconds(number: string, unit: string): number {
    if (unit === "year(s)") {
      return Number(number) * 31536000;
    }
    if (unit === "month(s)") {
      return Number(number) * 2592000;
    }
    if (unit === "day(s)") {
      return Number(number) * 86400;
    }
    if (unit === "hour(s)") {
      return Number(number) * 3600;
    }
    if (unit === "minute(s)") {
      return Number(number) * 60;
    }
    return Number(number);
  }

  commonConfig: DataQualityTagConfiguration = {
    tagName: "",
    observeTimeSpan: "1",
    observeTimeSpanUnit: "hour(s)",
    criticalTimeSpan: "1",
    criticalTimeSpanUnit: "day(s)",
  };

  get getButtonAvailability(): boolean {
    if (this.commonConfig.observeTimeSpan === "" || this.commonConfig.observeTimeSpan === "0") {
      return true;
    }
    if (this.commonConfig.criticalTimeSpan === "" || this.commonConfig.criticalTimeSpan === "0") {
      return true;
    }
    const invalidTag = this.dataAvailabilityConfigModel.find((tag) => {
      if (tag.tagName === "") {
        return true;
      }
      if (tag.observeTimeSpan === "" || tag.observeTimeSpan === "0") {
        return true;
      }
      if (tag.criticalTimeSpan === "" || tag.criticalTimeSpan === "0") {
        return true;
      }
      if (isNaN(Number(tag.observeTimeSpan)) || isNaN(Number(tag.criticalTimeSpan))) {
        return true;
      }
      return false;
    });
    if (invalidTag) {
      return true;
    }
    return false;
  }

  get alertMessage(): string {
    const invalidTag = this.dataAvailabilityConfigModel.find((tag) => {
      if (tag.tagName === "") {
        return true;
      }
      if (tag.observeTimeSpan === "" || tag.observeTimeSpan === "0") {
        return true;
      }
      if (tag.criticalTimeSpan === "" || tag.criticalTimeSpan === "0") {
        return true;
      }
      if (isNaN(Number(tag.observeTimeSpan)) || isNaN(Number(tag.criticalTimeSpan))) {
        return true;
      }
      return false;
    });

    if (this.commonConfig.observeTimeSpan === "" || this.commonConfig.observeTimeSpan === "0") {
      return "Observe time span cannot be empty or 0";
    }
    if (this.commonConfig.criticalTimeSpan === "" || this.commonConfig.criticalTimeSpan === "0") {
      return "Critical time span cannot be empty or 0";
    }
    if (isNaN(Number(this.commonConfig.observeTimeSpan)) || isNaN(Number(this.commonConfig.criticalTimeSpan))) {
      return "Time span must be a number";
    }

    if (invalidTag) {
      if (invalidTag.tagName === "") {
        return "Tag name cannot be empty";
      }
      if (invalidTag.observeTimeSpan === "" || invalidTag.observeTimeSpan == "0") {
        return "Observe time span cannot be empty or 0";
      }
      if (invalidTag.criticalTimeSpan === "" || invalidTag.criticalTimeSpan == "0") {
        return "Critical time span cannot be empty or 0";
      }
      if (isNaN(Number(invalidTag.observeTimeSpan)) || isNaN(Number(invalidTag.criticalTimeSpan))) {
        return "Time span must be a number";
      }
    }
    return "";
  }

  async submitConfiguration(): Promise<void> {
    if (this.applySameConfig) {
      this.dataAvailabilityConfigModel.forEach((tag) => {
        tag.observeTimeSpan = this.commonConfig.observeTimeSpan;
        tag.observeTimeSpanUnit = this.commonConfig.observeTimeSpanUnit;
        tag.criticalTimeSpan = this.commonConfig.criticalTimeSpan;
        tag.criticalTimeSpanUnit = this.commonConfig.criticalTimeSpanUnit;
      });
    } 

    var config: DataAvailabilityConfigType[] = this.dataAvailabilityConfigModel.map((item) => ({
      featureName: item.tagName,
      warningTimeSeconds: this.convertToSeconds(item.observeTimeSpan, item.observeTimeSpanUnit),
      criticalTimeSeconds: this.convertToSeconds(item.criticalTimeSpan, item.criticalTimeSpanUnit),
    }));
    await DataQuality.updateDataAvailabilityConfig(config);
    this.closeModal();
  }

  closeModal(): void {
    this.dialog = false;
  }
  }
