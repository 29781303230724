















  import { DataQualityRow } from "@/types/dataQuality/dataQualityTable";
  import { Component, Prop, Vue } from "vue-property-decorator";
  import DataQualityIndicator from "@/components/dataQuality/DataQualityIndicator.vue";
  import DataQualityPercentageIndicator from "@/components/dataQuality/DataQualityPercentageIndicator.vue";

@Component({
  components: {
    DataQualityIndicator,
    DataQualityPercentageIndicator,
  },
})
  export default class DataQualityTableRow extends Vue {
  @Prop({ type: Object }) dataQualityRow!: DataQualityRow;
  @Prop({ type: String, default: "" }) type!: string;
  @Prop({ type: String, default: "" }) link?: string;
  }

